import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Apply from '../pages/Apply';
import NotFound from '../layout/NotFound';

const Routes = () => {
  return (
    <div>
      <section className='container'>
        <Switch>
          <Route exact path='/apply' component={Apply} />
          <Route component={NotFound} />
        </Switch>
      </section>
    </div>
  );
};

export default Routes;

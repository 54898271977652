import React, { Fragment } from 'react';
import HomeVid from './HomeVid';
import HomeAbout from './HomeAbout';
import HomeDrivers from './HomeDrivers';
import HomeServices from './HomeServices';
import HomeStory from './HomeStory';
import ParallaxBlock1 from '../layout/ParallaxBlock1';
import ParallaxBlock2 from '../layout/ParallaxBlock2';
import ParallaxBlock3 from '../layout/ParallaxBlock3';
import ParallaxBlock4 from '../layout/ParallaxBlock4';
import Map from '../layout/Map';

import HomeMessage from './HomeMessage';

const Home = () => {
  return (
    <Fragment>
      <HomeVid />
      <HomeMessage />
      <HomeAbout />
      <ParallaxBlock2 />
      <HomeStory />
      <ParallaxBlock1 />
      <HomeDrivers />
      <ParallaxBlock4 />
      <HomeServices />
      <ParallaxBlock3 />
      <Map />
    </Fragment>
  );
};

export default Home;

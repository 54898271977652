import { combineReducers } from 'redux';
import { reducer as burgerMenu } from 'redux-burger-menu';
import alert from './alert';
import apply from './apply';

export default combineReducers({
  alert,
  apply,
  burgerMenu
});

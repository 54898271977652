import React, { Component, Fragment } from 'react';
import {
  Map as LeafletMap,
  GeoJSON,
  CircleMarker,
  Tooltip
} from 'react-leaflet';
import worldGeoJSON from 'geojson-world-map';
import Fade from 'react-reveal/Fade';

import { Grid } from 'semantic-ui-react';

class GeoJsonMap extends Component {
  render() {
    return (
      <Fragment>
        <Fade>
          <Grid>
            <Grid.Row>
              <div className='leaflet-container'>
                <div className='map-container'>
                  <LeafletMap
                    center={[39.322829, -94.306172]}
                    zoom={4}
                    minZoom={4}
                    maxZoom={4}
                    attributionControl={true}
                    zoomControl={false}
                    doubleClickZoom={true}
                    scrollWheelZoom={true}
                    dragging={true}
                    animate={true}
                    easeLinearity={0.35}
                    className='map-container'
                  >
                    <GeoJSON
                      data={worldGeoJSON}
                      style={() => ({
                        color: 'red',
                        weight: 0.7,
                        fillColor: 'darkslategray',
                        fillOpacity: 1
                      })}
                    />
                    <CircleMarker
                      color='red'
                      center={[39.322829, -94.306172]}
                      fillOpacity={0.8}
                      radius={25}
                      stroke={false}
                    />
                    <CircleMarker
                      color='red'
                      center={[39.322829, -94.306172]}
                      fillOpacity={0.6}
                      radius={100}
                      stroke={false}
                    />
                    <CircleMarker
                      color='red'
                      center={[39.322829, -94.306172]}
                      fillOpacity={0.4}
                      radius={200}
                      stroke={false}
                    >
                      <Tooltip>
                        <div className='map-text'>
                          <h2 className='text-primary'>AREAS OF SERVICE</h2>
                          <h5>
                            LOCAL - Kansas City Metropolitan
                            <br />
                            REGIONAL - 300-Mile Radius
                            <br />
                            LONG-HAUL - 1,000+ Mile Radius
                          </h5>
                        </div>
                      </Tooltip>
                    </CircleMarker>
                  </LeafletMap>
                </div>
              </div>
            </Grid.Row>
          </Grid>
        </Fade>
      </Fragment>
    );
  }
}

export default GeoJsonMap;

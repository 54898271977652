import React, { Fragment } from 'react';

import Bounce from 'react-reveal/Bounce';
import Fade from 'react-reveal/Fade';

import AnchorLink from 'react-anchor-link-smooth-scroll';

import { Grid, Icon, Image } from 'semantic-ui-react';
import SFTAlt from '../../assets/images/SFTAlt.png';
import TruckVideo from '../../assets/videos/TruckVideo.mov';

const HomeVid = () => {
  return (
    <Fragment>
      <Grid centered className='bg-dark video-box' verticalAlign='middle'>
        <div className='top-video'>
          <video autoPlay muted loop width='100%'>
            <source src={TruckVideo} />
          </video>
        </div>
        <div className='video-inner'>
          <Fade>
            <Image src={SFTAlt} size='medium' alt='SFT Logo' />
          </Fade>
          <br />
          <Bounce>
            <AnchorLink href='#home-content'>
              <Icon color='red' link name='arrow circle down' size='huge' />
            </AnchorLink>
          </Bounce>
        </div>
        <div className='video-small'>
          <Fade>
            <Image src={SFTAlt} size='small' />
          </Fade>
        </div>
      </Grid>
    </Fragment>
  );
};

export default HomeVid;

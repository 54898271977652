import React, { Fragment } from 'react';

import Bounce from 'react-reveal/Bounce';
import Fade from 'react-reveal/Fade';

import { Grid, Icon, Responsive } from 'semantic-ui-react';

const HomeServices = () => {
  return (
    <Fragment>
      <Responsive
        as={Grid}
        centered
        columns={3}
        textAlign='center'
        verticalAlign='middle'
        id='service-content'
        minWidth={767}
      >
        <Grid.Column width={5}>
          <Fade>
            <div className='grid' style={{ textAlign: 'justified' }}>
              <div style={{ verticalAlign: 'middle', marginBottom: '2rem' }}>
                <Bounce top>
                  <h3>
                    <Icon
                      circular
                      inverted
                      color='red'
                      name='shipping fast'
                      size='large'
                    />{' '}
                    <i>SERVICE</i>
                  </h3>
                </Bounce>
              </div>
              <p>
                We value the skill, experience, and dedication of our drivers
                and place the highest of standards on our team at every level of
                operation to ensure that all facets of delivery - from initial
                order to final dropoff - are fulfilled in a precise and proper
                fashion.
              </p>
            </div>
          </Fade>
        </Grid.Column>
        <Grid.Column width={5}>
          <Fade>
            <div className='bg-primary grid' style={{ textAlign: 'justified' }}>
              <div style={{ verticalAlign: 'middle', marginBottom: '2rem' }}>
                <Bounce top>
                  <h3>
                    <Icon
                      circular
                      inverted
                      color='black'
                      name='plus'
                      size='large'
                    />{' '}
                    <i>SAFETY</i>
                  </h3>
                </Bounce>
              </div>
              <p>
                We have established a culture of "safety-above-all." This
                ensures that every order reaches its destination in a timely and
                accident-free manner. We demand nothing but the best, and
                therefore maintain a zero-incidents policy at all times.
              </p>
            </div>
          </Fade>
        </Grid.Column>
        <Grid.Column
          width={5}
          className='grid'
          style={{ textAlign: 'justified' }}
        >
          <Fade>
            <div className='grid'>
              <div style={{ verticalAlign: 'middle', marginBottom: '2rem' }}>
                <Bounce top>
                  <h3>
                    <Icon
                      circular
                      inverted
                      color='red'
                      name='handshake'
                      size='large'
                    />{' '}
                    <i>SATISFACTION</i>
                  </h3>
                </Bounce>
              </div>
              <p>
                Our dedication to customer satisfaction is second-to-none, with
                an in-house team always at the ready to answer your calls or
                messages and ensure that your order is being fulfilled to all
                expectations and beyond. No matter what, your voice is always
                heard at SFT.
              </p>
            </div>
          </Fade>
        </Grid.Column>
      </Responsive>
      <Responsive
        as={Grid}
        centered
        columns={3}
        id='service-content'
        maxWidth={766}
      >
        <Grid.Row>
          <Fade>
            <div className='grid' style={{ textAlign: 'left' }}>
              <div style={{ verticalAlign: 'middle', marginBottom: '2rem' }}>
                <Fade>
                  <h3>
                    <Icon
                      circular
                      inverted
                      color='red'
                      name='truck'
                      size='large'
                    />
                    <i>SERVICE</i>
                  </h3>
                </Fade>
              </div>
              <p>
                We value the skill, experience, and dedication of our drivers
                and place the highest of standards on our team at every level of
                operation to ensure that all facets of delivery - from initial
                order to final dropoff - are fulfilled in a precise and proper
                fashion.
              </p>
            </div>
          </Fade>
        </Grid.Row>
        <Grid.Row>
          <Fade>
            <div className='bg-primary grid' style={{ textAlign: 'left' }}>
              <div style={{ verticalAlign: 'middle', marginBottom: '2rem' }}>
                <Fade>
                  <h3>
                    <Icon
                      circular
                      inverted
                      color='black'
                      name='plus'
                      size='large'
                    />{' '}
                    <i>SAFETY</i>
                  </h3>
                </Fade>
              </div>
              <p>
                We have established a culture of "safety-above-all." This
                ensures that every order reaches its destination in a timely and
                accident-free manner. We demand nothing but the best, and
                therefore maintain a zero-incidents policy at all times.
              </p>
            </div>
          </Fade>
        </Grid.Row>
        <Grid.Row>
          <Fade>
            <div className='grid' style={{ textAlign: 'left' }}>
              <div style={{ verticalAlign: 'middle', marginBottom: '2rem' }}>
                <Fade>
                  <h3>
                    <Icon
                      circular
                      inverted
                      color='red'
                      name='handshake'
                      size='large'
                    />{' '}
                    <i>SATISFACTION</i>
                  </h3>
                </Fade>
              </div>
              <p>
                Our dedication to customer satisfaction is second-to-none, with
                an in-house team always at the ready to answer your calls or
                messages and ensure that your order is being fulfilled to all
                expectations and beyond. No matter what, your voice is always
                heard at SFT.
              </p>
            </div>
          </Fade>
        </Grid.Row>
      </Responsive>
    </Fragment>
  );
};

export default HomeServices;

import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './components/pages/Home';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import Routes from './components/routing/Routes';

// Redux
import { Provider } from 'react-redux';
import store from './store';

// Styling
import './App.css';
import ScrollToTop from './components/layout/ScrollToTop';

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <ScrollToTop id='outer-container'>
          <Fragment>
            <Navbar />
            <Switch>
              <Route exact path='/' component={Home} />
              <Route component={Routes} />
            </Switch>
            <Footer />
          </Fragment>
        </ScrollToTop>
      </Router>
    </Provider>
  );
};

export default App;

import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Container, Form, Icon } from 'semantic-ui-react';
import Alert from './Alert';
import { setAlert } from '../../actions/alert';
import { contact } from '../../actions/contact';
import PropTypes from 'prop-types';

const Contact = ({ contact }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  });

  const { firstName, lastName, email, phone, message } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    contact({ firstName, lastName, email, phone, message });
  };

  return (
    <Fragment>
      <Container>
        <Alert />
        <h1>Contact Us</h1>
        <p>
          If you have questions regarding service requests, delivery status or
          any other Select Fleet Transportation matter, please leave us a brief
          message and a member of our support staff will respond as soon as
          possible.
        </p>
        <p>
          If you are interested in applying for an open position, please fill
          out an application <Link to='/apply'>here</Link>.
        </p>
        <p>
          If you would rather speak with a staff member directly, please contact
          Support at <a href='tel:816-628-3188'>(816) 628-3188</a>.
        </p>
        <br />
        <Form inverted onSubmit={e => onSubmit(e)}>
          <Form.Group widths='equal'>
            <Form.Input
              type='text'
              label='First Name'
              name='firstName'
              value={firstName}
              onChange={e => onChange(e)}
            />
            <Form.Input
              type='text'
              label='Last Name'
              name='lastName'
              value={lastName}
              onChange={e => onChange(e)}
            />
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Input
              type='email'
              label='Email'
              name='email'
              value={email}
              onChange={e => onChange(e)}
            />
            <Form.Input
              type='tel'
              label='Phone'
              name='phone'
              value={phone}
              onChange={e => onChange(e)}
            />
          </Form.Group>
          <Form.TextArea
            type='text'
            label='Your Message'
            name='message'
            value={message}
            onChange={e => onChange(e)}
          />
          <Button animated color='red' type='submit' value='Contact'>
            <Button.Content visible>Submit</Button.Content>
            <Button.Content hidden>
              <Icon name='mail' />
              <Icon name='arrow right' />
            </Button.Content>
          </Button>
        </Form>
      </Container>
    </Fragment>
  );
};

Contact.propTypes = {
  setAlert: PropTypes.func.isRequired,
  contact: PropTypes.func.isRequired
};

export default connect(null, { setAlert, contact })(Contact);

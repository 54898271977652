import axios from 'axios';
import { setAlert } from './alert';
import { CONTACT_SUCCESS, CONTACT_FAIL } from './types';

// Contact Form
export const contact = ({
  firstName,
  lastName,
  email,
  phone,
  message
}) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ firstName, lastName, email, phone, message });

  try {
    const res = await axios.post('/api/contact', body, config);

    dispatch({
      type: CONTACT_SUCCESS,
      payload: res.data
    });

    dispatch(
      setAlert(
        'Thanks! A representative will respond to your inquiry as soon as possible.',
        'success'
      )
    );
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: CONTACT_FAIL
    });
  }
};

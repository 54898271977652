import React, { Fragment } from 'react';

import Fade from 'react-reveal/Fade';

import { Grid, Image, Modal, Responsive } from 'semantic-ui-react';

import SFTWhite from '../../assets/images/SFTWhite.png';
import SFDWhite from '../../assets/images/SFDWhite.png';
import CTDWhite from '../../assets/images/CTDWhite.png';
import SFTAlt from '../../assets/images/SFTAlt.png';
import SFDAlt from '../../assets/images/SFDAlt.png';
import CTDAlt from '../../assets/images/CTDAlt.png';

const HomeAbout = () => {
  return (
    <Fragment>
      <Responsive
        as={Grid}
        id='home-content'
        centered
        textAlign='justified'
        verticalAlign='middle'
        minWidth={767}
      >
        <Grid.Row>
          <Fade>
            <h2 className='text-primary'>
              <i>THE BEST SELECTION</i>
            </h2>
          </Fade>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>
            <Fade>
              <div className='grid'>
                <Modal
                  trigger={
                    <Image
                      centered
                      src={SFDWhite}
                      size='small'
                      alt='SFD Logo'
                      className='about-image'
                    />
                  }
                  basic
                  size='large'
                >
                  <Modal.Content image verticalAlign='middle'>
                    <Image wrapped src={SFDAlt} />
                    <Modal.Description className='text-light'>
                      <p>
                        Select Fleet Dedicated is a non-asset-based carrier
                        established in 2018, staffed by highly experienced owner
                        operators, each with greater than fifteen years of
                        industry experience. Each SFD driver has a diverse
                        experience across the board in dryage / intermodal, dry
                        van, refrigerated, flatbed, and hopper transportation
                        methods.
                      </p>
                      <p>
                        <i>REGIONAL</i>: Servicing a 300-mile air radius around
                        the Kansas City rail (drayage / intermodal), general
                        commodities and agricultural shipping truckload market.
                        Regional drivers focus on all facets of domestic,
                        import, and export products.
                      </p>
                      <p>
                        <i>LONG-HAUL OTR</i>: Servicing a 1,000-mile air raidus
                        around the Kansas City market, with focus on the
                        agricultural and general freight truckload
                        transportation community.
                      </p>
                    </Modal.Description>
                  </Modal.Content>
                </Modal>
              </div>
            </Fade>
          </Grid.Column>
          <Grid.Column width={4}>
            <Fade>
              <div className='grid'>
                <Modal
                  trigger={
                    <Image
                      centered
                      wrapped
                      src={SFTWhite}
                      size='medium'
                      alt='SFT Logo'
                      className='about-image'
                    />
                  }
                  basic
                  size='large'
                >
                  <Modal.Content image>
                    <Image wrapped src={SFTAlt} />
                    <Modal.Description className='text-light'>
                      <p>
                        Select Fleet Transportation, Inc. was formed in 2017 by
                        a leadership team with over seventy years of combined
                        experience in various sectors of the transportation
                        industry. This experience has come in the form of
                        leadership roles in all areas of trucking: LTL, TL,
                        Drayage / Intermodal, Flatbed and Refrigerated, and
                        Class 1 / Short-line Railroad Operational Leadership in
                        Transportation, Safety, Sales, and Marketing.
                      </p>
                      <p>
                        This earned understanding of the transportation industry
                        has enabled Select Fleet Transportation to grow existing
                        asset-based trucking operations - the Creel Trucking
                        Division, which was established in 1990 - and form our
                        own non-asset-based trucking division, Select Fleet
                        Dedicated, which is served by owner operations / leaded
                        on trucks.
                      </p>
                    </Modal.Description>
                  </Modal.Content>
                </Modal>
              </div>
            </Fade>
          </Grid.Column>
          <Grid.Column width={4}>
            <Fade>
              <div className='grid'>
                <Modal
                  trigger={
                    <Image
                      centered
                      src={CTDWhite}
                      size='small'
                      alt='CTD Logo'
                      className='about-image'
                    />
                  }
                  basic
                  size='large'
                >
                  <Modal.Content image verticalAlign='middle'>
                    <Image wrapped src={CTDAlt} />
                    <Modal.Description className='text-light'>
                      <p>
                        The Creel Trucking Division is an asset-based carrier
                        building an industry leading reputation since 1990.
                        Staffed by over forty highly experienced company drivers
                        in all sectors of haulage with over forty power units,
                        over ninety 53' dry vans, and over twenty heavy-use
                        chassis.
                      </p>
                      <p>
                        <i>LOCAL</i>: Servicing the greater Kansas City
                        metropolitan area in the rail drayage / intermodal
                        markets and dry van automotive / general freight shuttle
                        networks.
                      </p>
                      <p>
                        <i>REGIONAL</i>: Servicing a 300-mile air radius around
                        the Kansas City rail infrastructure. Longstanding
                        contracts with numerous international Steamship Lines,
                        Forwarders, and Import / Export Logistics Companies.
                      </p>
                      <p>
                        <i>LONG-HAUL OTR</i>: Servicing a 1,000-mile air radius
                        around the Kansas City market, with focus on the
                        automotive and general freight truckload transportation
                        community.
                      </p>
                    </Modal.Description>
                  </Modal.Content>
                </Modal>
              </div>
            </Fade>
          </Grid.Column>
        </Grid.Row>
      </Responsive>
      <Responsive
        as={Grid}
        id='home-content'
        centered
        textAlign='left'
        maxWidth={766}
      >
        <Grid.Row>
          <Fade>
            <h2 className='text-primary'>
              <i>THE BEST SELECTION</i>
            </h2>
          </Fade>
        </Grid.Row>
        <Grid.Row>
          <Fade>
            <div className='grid'>
              <Modal
                trigger={
                  <Image
                    centered
                    wrapped
                    src={SFTWhite}
                    size='medium'
                    alt='SFT Logo'
                    className='about-image'
                  />
                }
                basic
                size='small'
              >
                <Modal.Content image>
                  <Image size='medium' src={SFTAlt} />
                  <Modal.Description className='text-light'>
                    <p>
                      Select Fleet Transportation, Inc. was formed in 2017 by a
                      leadership team with over seventy years of combined
                      experience in various sectors of the transportation
                      industry. This experience has come in the form of
                      leadership roles in all areas of trucking: LTL, TL,
                      Drayage / Intermodal, Flatbed and Refrigerated, and Class
                      1 / Short-line Railroad Operational Leadership in
                      Transportation, Safety, Sales, and Marketing.
                    </p>
                    <p>
                      This earned understanding of the transportation industry
                      has enabled Select Fleet Transportation to grow existing
                      asset-based trucking operations - the Creel Trucking
                      Division, which was established in 1990 - and form our own
                      non-asset-based trucking division, Select Fleet Dedicated,
                      which is served by owner operations / leaded on trucks.
                    </p>
                  </Modal.Description>
                </Modal.Content>
              </Modal>
            </div>
          </Fade>
        </Grid.Row>
        <Grid.Row>
          <Fade>
            <div className='grid'>
              <Modal
                trigger={
                  <Image
                    centered
                    src={CTDWhite}
                    size='medium'
                    alt='CTD Logo'
                    className='about-image'
                  />
                }
                basic
                size='small'
              >
                <Modal.Content image>
                  <Image size='medium' src={CTDAlt} />
                  <Modal.Description className='text-light'>
                    <p>
                      The Creel Trucking Division is an asset-based carrier
                      building an industry leading reputation since 1990.
                      Staffed by over forty highly experienced company drivers
                      in all sectors of haulage with over forty power units,
                      over ninety 53' dry vans, and over twenty heavy-use
                      chassis.
                    </p>
                    <p>
                      <i>LOCAL</i>: Servicing the greater Kansas City
                      metropolitan area in the rail drayage / intermodal markets
                      and dry van automotive / general freight shuttle networks.
                    </p>
                    <p>
                      <i>REGIONAL</i>: Servicing a 300-mile air radius around
                      the Kansas City rail infrastructure. Longstanding
                      contracts with numerous international Steamship Lines,
                      Forwarders, and Import / Export Logistics Companies.
                    </p>
                    <p>
                      <i>LONG-HAUL OTR</i>: Servicing a 1,000-mile air radius
                      around the Kansas City market, with focus on the
                      automotive and general freight truckload transportation
                      community.
                    </p>
                  </Modal.Description>
                </Modal.Content>
              </Modal>
            </div>
          </Fade>
        </Grid.Row>
        <Grid.Row>
          <Fade>
            <div className='grid'>
              <Modal
                trigger={
                  <Image
                    centered
                    src={SFDWhite}
                    size='medium'
                    alt='SFD Logo'
                    className='about-image'
                  />
                }
                basic
                size='small'
              >
                <Modal.Content image>
                  <Image size='medium' src={SFDAlt} />
                  <Modal.Description className='text-light'>
                    <p>
                      Select Fleet Dedicated is a non-asset-based carrier
                      established in 2018, staffed by highly experienced owner
                      operators, each with greater than fifteen years of
                      industry experience. Each SFD driver has a diverse
                      experience across the board in dryage / intermodal, dry
                      van, refrigerated, flatbed, and hopper transportation
                      methods.
                    </p>
                    <p>
                      <i>REGIONAL</i>: Servicing a 300-mile air radius around
                      the Kansas City rail (drayage / intermodal), general
                      commodities and agricultural shipping truckload market.
                      Regional drivers focus on all facets of domestic, import,
                      and export products.
                    </p>
                    <p>
                      <i>LONG-HAUL OTR</i>: Servicing a 1,000-mile air raidus
                      around the Kansas City market, with focus on the
                      agricultural and general freight truckload transportation
                      community.
                    </p>
                  </Modal.Description>
                </Modal.Content>
              </Modal>
            </div>
          </Fade>
        </Grid.Row>
      </Responsive>
    </Fragment>
  );
};

export default HomeAbout;

import React from 'react';
import { Message, Header } from 'semantic-ui-react';

const HomeMessage = () => (
  <Message warning>
    <Header as='h2' textAlign='center'>
      A Message To Our Customers
    </Header>
    <br />
    <div style={({ textAlign: 'justify' }, { fontWeight: 'bold' })}>
      <p>
        Select Fleet Transportation, Inc. continues to operate amid the growing
        COVID-19 pandemic, fulfilling the essential functions needed by the
        transportation industry. This is to maintain supply chains in other
        fundamental industries such as healthcare and food distribution in order
        to ensure economic stability during difficult times.
      </p>
      <p>
        As many industries make necessary changes to their supply chains and
        production schedules, we too are constantly making adjustments to trip
        plans in order to align with the shifting demands. These changes may
        affect the schedule of your shipments, but rest assured knowing our
        operations, dispatch and marketing teams are always on-call to answer
        your questions and keep communications clear.
      </p>
      <p>
        SFT is constantly committed to our customers and partners as we continue
        to operate during this public health crisis in order to ensure all
        sectors retain functionality. We still believe in safety, service and
        satisfaction above all factors and will continue to provide these
        functions to the best of our capabilities, no matter the challenge.
      </p>
      <p>
        Thank you for being a loyal customer or partner. We wish you nothing but
        the best of health during these trying times.
      </p>
    </div>
  </Message>
);

export default HomeMessage;

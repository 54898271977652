import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Icon, Image, Responsive } from 'semantic-ui-react';

import Fade from 'react-reveal/Fade';

import SFTGroupWhite from '../../assets/images/SFTGroupWhite.png';

const Footer = () => {
  return (
    <Fragment>
      <Responsive
        as={Grid}
        columns={3}
        centered
        className='bg-dark page-footer'
        minWidth={767}
      >
        <Grid.Column>
          <Fade left>
            <Grid.Row>
              <div className='grid'>
                <h4 className='nav-link'>
                  <Link to={'/'}>HOME</Link>
                </h4>
                <h4 className='nav-link'>
                  <Link to={'/apply'}>DRIVE WITH US</Link>
                </h4>
                <h4>
                  FOLLOW US ON{' '}
                  <a
                    href='https://www.linkedin.com/company/select-fleet-transportation'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <Icon name='linkedin' size='big' />
                  </a>
                </h4>
              </div>
            </Grid.Row>
            <Grid.Row>
              <div className='grid-left'>
                <p>
                  {' '}
                  <a href='tel:816-628-3188'>(816) 628-3188</a>
                </p>
                <p>
                  <a href='mailto:support@selectft.com'>support@selectft.com</a>
                </p>
              </div>
            </Grid.Row>
          </Fade>
        </Grid.Column>
        <Grid.Column>
          <Grid.Row>
            <Fade>
              <Image src={SFTGroupWhite} centered size='medium' />
            </Fade>
          </Grid.Row>
        </Grid.Column>
        <Grid.Column textAlign='right'>
          <Fade right>
            <Grid.Row>
              <div className='grid'>
                <h4 className='nav-link'>
                  <a
                    href='https://www.google.com/maps/place/Select+Fleet+Transportation,+Inc./@39.3228377,-94.3068156,15z/data=!4m2!3m1!1s0x0:0xe4334de246650481?sa=X&ved=2ahUKEwiW1-Goy6bnAhWbbs0KHdeeBnsQ_BIwC3oECAwQCA'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    CREEL TRUCKING DIVISION
                  </a>
                </h4>
                <h4 className='nav-link'>
                  <a
                    href='https://www.google.com/maps/place/1125+N+Monroe+Ave,+Kansas+City,+MO+64120/@39.1281994,-94.535739,17.88z/data=!4m13!1m7!3m6!1s0x87c0fa4759ed35c7:0x839f07467d09266a!2s1125+N+Monroe+Ave,+Kansas+City,+MO+64120!3b1!8m2!3d39.1285066!4d-94.5359332!3m4!1s0x87c0fa4759ed35c7:0x839f07467d09266a!8m2!3d39.1285066!4d-94.5359332'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    KANSAS CITY LOT
                  </a>
                </h4>
              </div>
            </Grid.Row>
            <div className='grid-right'>
              <p>Select Fleet Transportation, Inc © 2020</p>
            </div>
          </Fade>
        </Grid.Column>
      </Responsive>
      <Responsive
        as={Grid}
        columns={3}
        centered
        className='bg-dark page-footer'
        maxWidth={766}
      >
        <Grid.Row>
          <Fade>
            <div className='grid'>
              <h4 className='nav-link'>
                <Link to={'/'}>HOME</Link>
              </h4>
              <h4 className='nav-link'>
                <Link to={'/apply'}>DRIVE WITH US</Link>
              </h4>
              <h4>FOLLOW US:</h4>
              <a
                href='https://www.linkedin.com/company/select-fleet-transportation'
                target='_blank'
                rel='noopener noreferrer'
              >
                <Icon name='linkedin' size='big' />
              </a>
              <p>
                {' '}
                <a href='tel:816-628-3188'>(816) 628-3188</a>
              </p>
              <p>
                <a href='mailto:support@selectft.com'>support@selectft.com</a>
              </p>
            </div>
          </Fade>
        </Grid.Row>
        <Grid.Row>
          <Fade>
            <Image src={SFTGroupWhite} centered size='medium' />
          </Fade>
        </Grid.Row>
        <Grid.Row>
          <Fade right>
            <div className='grid'>
              <a
                href='https://www.google.com/maps/place/Select+Fleet+Transportation,+Inc./@39.3228377,-94.3068156,15z/data=!4m2!3m1!1s0x0:0xe4334de246650481?sa=X&ved=2ahUKEwiW1-Goy6bnAhWbbs0KHdeeBnsQ_BIwC3oECAwQCA'
                target='_blank'
                rel='noopener noreferrer'
              >
                <p>12612 Rhodus Road</p>
                <p>Excelsior Springs, MO | 64024</p>
              </a>
              <p>Select Fleet Transportation, Inc</p>
              <p> © 2020</p>
            </div>
          </Fade>
        </Grid.Row>
      </Responsive>
    </Fragment>
  );
};

export default Footer;

import axios from 'axios';
import { setAlert } from './alert';
import { APPLY_SUCCESS, APPLY_FAIL } from './types';

// Application
export const apply = ({
  firstName,
  lastName,
  email,
  phone,
  license,
  exp,
  types,
  birthday,
  street,
  city,
  state,
  postal,
  country
}) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({
    firstName,
    lastName,
    email,
    phone,
    license,
    exp,
    types,
    birthday,
    street,
    city,
    state,
    postal,
    country
  });

  try {
    const res = await axios.post('/api/apply', body, config);

    dispatch({
      type: APPLY_SUCCESS,
      payload: res.data
    });

    dispatch(
      setAlert(
        'Thanks! A representative will respond to your application as soon as possible.',
        'success'
      )
    );
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: APPLY_FAIL
    });
  }
};

import React from 'react';

import { Parallax } from 'react-parallax';

import ParallaxImage from '../../assets/images/StockTruck8.jpg';

const ParallaxBlock2 = () => {
  return (
    <Parallax bgImage={ParallaxImage} blur={{ min: -1, max: 3 }}>
      <div style={{ height: 300 }}>
        <div className='dark-overlay'></div>
      </div>
    </Parallax>
  );
};

export default ParallaxBlock2;

import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { Button, Container, Icon } from 'semantic-ui-react';

const NotFound = () => {
  return (
    <Fragment>
      <Container className='page-not-found'>
        <div className='landing-inner'>
          <h1 className='text-primary'>
            <Icon name='exclamation circle' size='big' /> Page Not Found{' '}
            <Icon name='exclamation circle' size='big' />
          </h1>
          <h4>Sorry, this page doesn't seem to exist.</h4>
          <Link to='/'>
            <Button animated color='red'>
              <Button.Content visible>Go Home</Button.Content>
              <Button.Content hidden>
                <Icon name='home' />
              </Button.Content>
            </Button>
          </Link>
        </div>
      </Container>
    </Fragment>
  );
};

export default NotFound;

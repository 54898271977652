import React, { Fragment } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { decorator as reduxBurgerMenu } from 'redux-burger-menu';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import Contact from './Contact';

import { Image, Modal } from 'semantic-ui-react';

import SFTAlt from '../../assets/images/SFTAlt.png';

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false
    };
  }

  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }

  closeMenu() {
    this.setState(state => ({ menuOpen: !state.menuOpen }));
  }

  render() {
    return (
      <Menu
        isOpen={this.state.menuOpen}
        onStateChange={state => this.handleStateChange(state)}
        pageWrapId={'page-wrap'}
        outerContainerId={'outer-container'}
      >
        <Fragment>
          <NavLink to='/' onClick={() => this.closeMenu()}>
            <Image src={SFTAlt} size='small' className='menu-logo' />
          </NavLink>
          <div className='menu-link'>
            <NavLink to='/apply' onClick={() => this.closeMenu()}>
              <h4>
                <i>DRIVE WITH US</i>
              </h4>
            </NavLink>
          </div>
          <div className='contact-button'>
            <Modal
              trigger={
                <h4 onClick={() => this.closeMenu()}>
                  <i>CONTACT US</i>
                </h4>
              }
              basic
              closeIcon
              size='small'
            >
              <Contact />
            </Modal>
          </div>
        </Fragment>
      </Menu>
    );
  }
}

export default connect()(reduxBurgerMenu(Navbar));

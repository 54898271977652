import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Icon } from 'semantic-ui-react';
import Alert from '../layout/Alert';
import { setAlert } from '../../actions/alert';
import { apply } from '../../actions/apply';
import PropTypes from 'prop-types';

const Apply = ({ apply }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    license: '',
    exp: '',
    types: '',
    birthday: '',
    street: '',
    city: '',
    state: '',
    postal: '',
    country: ''
  });

  const {
    firstName,
    lastName,
    email,
    phone,
    license,
    exp,
    types,
    birthday,
    street,
    city,
    state,
    postal,
    country
  } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    apply({
      firstName,
      lastName,
      email,
      phone,
      license,
      exp,
      types,
      birthday,
      street,
      city,
      state,
      postal,
      country
    });
  };

  return (
    <Fragment>
      <section className='landing4'>
        <div className='darker-overlay'>
          <div className='landing-inner'>
            <h1 className='text-primary'>DRIVE WITH US</h1>
            <Alert />
            <div style={({ marginTop: '1.5rem' }, { marginBottom: '1.5rem' })}>
              <p>
                Apply here to see how you fit in to the Select Fleet
                Transportation driver corps! SFT is seeking experienced and
                highly motivated drivers with a minimum of 18 months experience
                in Class A CDL licensed driving, a stable and verifiable work
                history and superlative driving record.
              </p>
              <p>
                Please fill out this quick application below and a
                representative will respond shortly.
              </p>
            </div>
            <Form inverted onSubmit={e => onSubmit(e)}>
              <Form.Group widths='equal'>
                <Form.Input
                  type='text'
                  label='First Name'
                  name='firstName'
                  value={firstName}
                  onChange={e => onChange(e)}
                />
                <Form.Input
                  type='text'
                  label='Last Name'
                  name='lastName'
                  value={lastName}
                  onChange={e => onChange(e)}
                />
                <Form.Input
                  type='text'
                  label='Email'
                  name='email'
                  value={email}
                  onChange={e => onChange(e)}
                />
                <Form.Input
                  type='text'
                  label='Phone Number'
                  name='phone'
                  value={phone}
                  onChange={e => onChange(e)}
                />
              </Form.Group>
              <Form.Group widths='equal'>
                <Form.Input
                  type='text'
                  label='Class A CDL License Number'
                  name='license'
                  value={license}
                  onChange={e => onChange(e)}
                />
                <Form.Input
                  type='text'
                  label='Class A CDL Years of Experience'
                  name='exp'
                  value={exp}
                  onChange={e => onChange(e)}
                />
                <Form.Input
                  type='text'
                  label='Driving Experience (List All)'
                  placeholder='Intermodal, Dry-Van, Reefer, Flatbed, etc.'
                  name='types'
                  value={types}
                  onChange={e => onChange(e)}
                />
                <Form.Input
                  type='date'
                  label='Date of Birth'
                  name='birthday'
                  value={birthday}
                  onChange={e => onChange(e)}
                />
              </Form.Group>
              <p>Please provide your CURRENT address.</p>
              <Form.Input
                type='text'
                label='Street'
                name='street'
                value={street}
                onChange={e => onChange(e)}
              />
              <Form.Group widths='equal'>
                <Form.Input
                  type='text'
                  label='City'
                  name='city'
                  value={city}
                  onChange={e => onChange(e)}
                />
                <Form.Input
                  type='text'
                  label='State'
                  name='state'
                  value={state}
                  onChange={e => onChange(e)}
                />
                <Form.Input
                  type='text'
                  label='Zip/Postal Code'
                  name='postal'
                  value={postal}
                  onChange={e => onChange(e)}
                />
                <Form.Input
                  type='text'
                  label='Country'
                  name='country'
                  value={country}
                  onChange={e => onChange(e)}
                />
              </Form.Group>
              <Button animated color='red' type='submit'>
                <Button.Content visible>Submit</Button.Content>
                <Button.Content hidden>
                  <Icon name='check' />
                </Button.Content>
              </Button>
            </Form>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

Apply.propTypes = {
  setAlert: PropTypes.func.isRequired,
  apply: PropTypes.func.isRequired
};

export default connect(null, { setAlert, apply })(Apply);

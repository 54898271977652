import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import Fade from 'react-reveal/Fade';

import { Button, Grid, Icon, Image, Responsive } from 'semantic-ui-react';
import StockDriver from '../../assets/images/StockTruck5.jpg';

const HomeDrivers = () => {
  return (
    <Fragment>
      <Responsive
        as={Grid}
        centered
        columsn={2}
        verticalAlign='middle'
        id='driver-content'
        minWidth={770}
      >
        <Grid.Column width={8}>
          <Fade left>
            <Image src={StockDriver} fluid />
          </Fade>
        </Grid.Column>
        <Grid.Column width={8}>
          <Fade>
            <div className='grid'>
              <h1 className='text-primary'>
                <i>DRIVE WITH US</i>
              </h1>
              <Grid.Row>
                <p>
                  Select Fleet Transportation takes pride in its relationship
                  with our drivers and is always seeking experienced, motivated
                  and excellence-minded drivers to add to our skilled corps.
                  Each SFT driver is expected to dedicate themselves to
                  providing second-to-none service that goes above and beyond
                  all customer expectations and can expect to receive supreme
                  support and cooperation from all operating sectors within the
                  organization. We are currently hiring drivers with a stable
                  and verified work history, a superlative driving record and a
                  minimum of 18 months experience of Class A CDL licensed
                  driving.
                </p>
                <p>Apply to join the SFT team by following the link below.</p>
                <Link to='/apply'>
                  <Button
                    animated
                    color='red'
                    type='submit'
                    value='Register'
                    className='button'
                  >
                    <Button.Content visible>Apply</Button.Content>
                    <Button.Content hidden>
                      <Icon name='arrow right' />
                    </Button.Content>
                  </Button>
                </Link>
              </Grid.Row>
            </div>
          </Fade>
        </Grid.Column>
      </Responsive>
      <Responsive as={Grid} maxWidth={769}>
        <Grid.Row>
          <Fade>
            <div className='grid'>
              <h1 className='text-primary'>
                <i>DRIVE WITH US</i>
              </h1>
              <Grid.Row>
                <p>
                  Select Fleet Transportation takes pride in its relationship
                  with our drivers and is always seeking experienced, motivated
                  and excellence-minded drivers to add to our skilled corps.
                  Each SFT driver is expected to dedicate themselves to
                  providing second-to-none service that goes above and beyond
                  all customer expectations and can expect to receive supreme
                  support and cooperation from all operating sectors within the
                  organization. We are currently hiring drivers with a stable
                  and verified work history, a superlative driving record and a
                  minimum of 18 months experience of Class A CDL licensed
                  driving.
                </p>
                <p>Apply to join the SFT team by following the link below.</p>
                <Link to='/apply'>
                  <Button
                    animated
                    color='red'
                    type='submit'
                    value='Register'
                    className='button'
                  >
                    <Button.Content visible>Apply</Button.Content>
                    <Button.Content hidden>
                      <Icon name='arrow right' />
                    </Button.Content>
                  </Button>
                </Link>
              </Grid.Row>
            </div>
          </Fade>
        </Grid.Row>
      </Responsive>
    </Fragment>
  );
};

export default HomeDrivers;

import React, { Fragment } from 'react';

import Fade from 'react-reveal/Fade';

import { Grid, Image, Responsive } from 'semantic-ui-react';

import Placeholder from '../../assets/images/StockTruck4.jpg';

const HomeStory = () => {
  return (
    <Fragment>
      <Responsive
        as={Grid}
        centered
        textAlign='justified'
        verticalAlign='middle'
        id='about-content'
        minWidth={777}
      >
        <Grid.Column width={8}>
          <div className='grid'>
            <Fade>
              <h1 className='text-primary'>
                <i>OUR STORY</i>
              </h1>
              <p>
                Select Fleet Transportation is one of the Midwest's most trusted
                local and regional carriers. Established in 2017, SFT serves a
                diverse segment of the transportation industry while
                prioritizing the three S's:{' '}
                <i className='text-primary'>SAFETY</i>,{' '}
                <i className='text-primary'>SERVICE</i>, and{' '}
                <i className='text-primary'>SATISFACTION</i>.
              </p>
              <p>
                All operating segments of Select Fleet Transportation pride
                ourselves on our relationships with our <i>DRIVERS</i>, all of
                whom are dedicated to providing excellent service that goes
                above and beyond customer expectations. Our Operations
                Department is on-call 24 hours a day, coordinating loads and
                monitoring them en route to ensure that consistent, on-time, and
                error-free performance is achieved every time. This dedication
                to excellence is coupled with a proven record of safety that
                ensures not only the integrity of the cargo for which we are
                responsible, but the well-being of both our drivers and the
                public with whom we share the road.
              </p>
            </Fade>
          </div>
        </Grid.Column>
        <Grid.Column width={8}>
          <Fade right>
            <Image src={Placeholder} alt='SFT Placeholder' />
          </Fade>
        </Grid.Column>
      </Responsive>
      <Responsive as={Grid} textAlign='left' id='about-content' maxWidth={778}>
        <Grid.Row>
          <div className='grid'>
            <Fade>
              <h1 className='text-primary'>
                <i>Our Story</i>
              </h1>
              <p>
                Select Fleet Transportation is one of the Midwest's most trusted
                local and regional carriers. Established in 2017, SFT serves a
                diverse segment of the transportation industry while
                prioritizing the three S's:{' '}
                <i className='text-primary'>SAFETY</i>,{' '}
                <i className='text-primary'>SERVICE</i>, and{' '}
                <i className='text-primary'>SATISFACTION</i>.
              </p>
              <p>
                All operating segments of Select Fleet Transportation pride
                ourselves on our relationships with our <i>DRIVERS</i>, all of
                whom are dedicated to providing excellent service that goes
                above and beyond customer expectations. Our Operations
                Department is on-call 24 hours a day, coordinating loads and
                monitoring them en route to ensure that consistent, on-time, and
                error-free performance is achieved every time. This dedication
                to excellence is coupled with a proven record of safety that
                ensures not only the integrity of the cargo for which we are
                responsible, but the well-being of both our drivers and the
                public with whom we share the road.
              </p>
            </Fade>
          </div>
        </Grid.Row>
      </Responsive>
    </Fragment>
  );
};

export default HomeStory;
